// https://vuex.vuejs.org/guide/modules.html

import { GET_PROJECT } from '../../schema/businessUnit';
import store from '../index';

export default {
    namespaced: true,

    state: {
        project_id: undefined,
        project: undefined,
    },

    getters: {
        //Selected (current) project of the selected Business Unit
        project_id: (state) => {
            return state.project_id;
        },
        project: (state) => {
            return state.project;
        },

        loading: (state) => {
            return (
                state.project === undefined && state.project_id !== undefined
            );
        },
    },

    mutations: {
        clear: (state) => {
            state.project = undefined;
        },
        set_project_id: (state, project_id) => {
            state.project_id = project_id;
        },
        update_project: (state, project) => {
            state.project = project;
        },
    },

    actions: {
        wait_project_load: async ({ getters }) => {
            while (getters.loading) {
                await new Promise((r) => setTimeout(r, 100));
            }
        },

        switch_project: ({ commit, rootGetters }, payload) => {
            commit('clear');
            commit('set_project_id', payload.project_id);

            return new Promise((resolve, reject) => {
                rootGetters.apollo
                    .query({
                        query: GET_PROJECT,
                        variables: {
                            id: payload.project_id,
                        },
                        fetchPolicy: 'no-cache',
                        notifyOnNetworkStatusChange: true,
                    })
                    .then(({ data, loading }) => {
                        if (!loading && data !== undefined) {
                            commit('update_project', data.project);
                            commit(
                                'registries/set_registries',
                                data.project.registries,
                                { root: true },
                            );

                            store
                                .dispatch(
                                    'registries/subscribe',
                                    { project_id: data.project.id },
                                    { root: true },
                                )
                                .then();
                            store
                                .dispatch(
                                    'deviceTypes/load_device_types',
                                    { project_id: data.project.id },
                                    { root: true },
                                )
                                .then();
                            store
                                .dispatch(
                                    'topics/subscribe',
                                    {},
                                    { root: true },
                                )
                                .then();
                            store
                                .dispatch(
                                    'actionTypes/subscribe',
                                    { project_id: data.project.id },
                                    { root: true },
                                )
                                .then();

                            if (
                                store.getters['connectors/activated'] === true
                            ) {
                                store
                                    .dispatch('connectors/loadConnectors', {
                                        projectId: data.project.id,
                                    })
                                    .catch((err) => {
                                        console.error(err.message);
                                    });
                            }

                            if (
                                store.getters['ui/isAlertRuleEnabled'](
                                    data.project.id,
                                )
                            ) {
                                store
                                    .dispatch('alertRules/subscribe', {
                                        project_id: data.project.id,
                                        filters: {},
                                    })
                                    .catch((error) => {
                                        console.error(error.message);
                                    });
                                store
                                    .dispatch('alertRules/load_notifiable', {
                                        project_id: data.project.id,
                                    })
                                    .catch((error) => {
                                        console.error(error.message);
                                    });
                                store
                                    .dispatch(
                                        'alerts/subscribe', 
                                        { project_id: data.project.id }
                                    )
                                    .catch((error) => {
                                        console.error(error.message);
                                    });
                            }

                            resolve(data.project);
                        }
                    })
                    .catch((error) => {
                        commit('set_project_id', undefined);
                        console.error(error);
                        reject(error);
                    });
            });
        },
    },
};
