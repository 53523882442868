<template>
    <div class="card-content">
        <div class="content">
            <nav class="level">
                <div class="level-item has-text-centered">
                    <div v-if="!designed_gateway">
                        <p class="heading">Device</p>
                        <p class="title">Self</p>
                        <p class="subtitle">(No Gateway)</p>
                    </div>
                    <div v-if="designed_gateway">
                        <p class="heading">Gateway</p>
                        <p class="title">ID: {{ designed_gateway }}</p>
                        <p class="subtitle">
                            <router-link
                                class="button is-primary is-small"
                                :to="{
                                    name: 'devices-edit',
                                    params: { id: designed_gateway },
                                }"
                            >
                                View
                            </router-link>
                        </p>
                    </div>
                </div>

                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Wait</p>
                        <p class="title">{{ waitTime }}</p>
                        <p class="subtitle" title="Created At">
                            {{ device_action.created_at | formatDate }}
                        </p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Duration</p>
                        <p class="title">{{ durationTime }}</p>
                        <p
                            class="subtitle"
                            v-if="device_action.started_at"
                            title="Started At"
                        >
                            {{ device_action.started_at | formatDate }}
                        </p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Status</p>
                        <p class="title">{{ device_action.status }}</p>

                        <p
                            v-if="device_action.done_at"
                            class="subtitle"
                            title="Done At"
                        >
                            {{ device_action.done_at | formatDate }}
                        </p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Sending Attempts</p>
                        <p class="title">{{ device_action.sending_attempt_count }}</p>
                        <p class="heading">Execution Attempts</p>
                        <p class="title">{{ device_action.execution_attempt_count }}</p>
                    </div>
                </div>
            </nav>

            <progress
                v-if="!isPending"
                class="progress is-large"
                :class="{
                    'is-danger': isError,
                }"
                :value="progression"
                max="100"
            ></progress>

            <label v-if="isPending && device_action.device" class="label">
                Is Accessible:
                <span>
                    {{ device_action.device.is_accessible ? 'Yes' : 'No' }}
                </span>
            </label>

            <label class="label">Payload</label>
            <div class="payload">
              <div class="payload-content">
                  {{ device_action.action.payload | b64decode }}
              </div>
            </div>

            <label
                v-if="device_action.sent_at && !device_action.started_at"
                class="label"
                >Sent At</label
            >
            <p v-if="device_action.sent_at && !device_action.started_at">
                {{ device_action.sent_at | formatDate }}
            </p>
        </div>
    </div>
</template>

<style scoped>
.payload{
  max-width: 100%;
  display: inline-grid;
}
.payload-content{
  padding: 1rem;
  overflow-x:scroll;
  overflow-y: hidden;
}
</style>

<script>
import { formatDistance } from 'date-fns';

export default {
    name: 'device-action-card-content',
    props: {
        device_action: {
            type: Object,
            required: true,
        },
        progression: {
            required: true,
        },
    },
    data: () => {
        return {
            is_visible: false,
        };
    },
    computed: {
        designed_gateway() {
            if (
                this.device_action !== null &&
                this.device_action.device !== null &&
                this.device_action.device.reachable_by
            ) {
                return this.device_action.device.reachable_by;
            }

            return null;
        },

        waitTime() {
            const createdAt = new Date(this.device_action.created_at);
            const startAt = this.device_action.started_at
                ? new Date(this.device_action.started_at)
                : new Date();
            return formatDistance(createdAt, startAt);
        },

        durationTime() {
            if (!this.device_action.started_at) return '-';

            const startAt = new Date(this.device_action.started_at);
            const doneAt = this.device_action.done_at
                ? new Date(this.device_action.done_at)
                : new Date();

            return formatDistance(startAt, doneAt);
        },

        isSuccess() {
            return this.device_action.status === 'success';
        },
        isPending() {
            return this.device_action.status === 'pending';
        },
        isError() {
            return (
                this.device_action.status === 'failure' ||
                this.device_action.status === 'cancel'
            );
        },
    },
};
</script>
