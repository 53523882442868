import gql from 'graphql-tag';

export const LIST_ACTIONS = gql`
    query actions($projectId: Int64!, $filters: FiltersInput!) {
        actions(projectId: $projectId, filters: $filters) {
            data {
                id
                action_type_id
                action_type {
                    name
                    type
                }
                payload
                is_scheduled
                activate_at
                end_at
                cron

                devices {
                    status
                    progression
                    created_at
                    deleted_at
                }
                progression
            }
            pagination {
                total
                last_page
            }
        }
    }
`;

//return deviceActions
export const SUBSCRIPTION_ACTION = gql`
    subscription liveAction($projectId: Int64!, $actionId: Int64!) {
        liveAction(projectId: $projectId, actionId: $actionId) {
            id
            action_id
            action {
                id
                action_type_id
                action_type {
                    name
                    type
                }
                payload
                is_scheduled
                activate_at
                end_at
                cron
                progression
            }
            device_id
            device {
                is_accessible
                reachable_by
            }
            status
            progression
            created_at
            sent_at
            started_at
            done_at
            sending_attempt_count
            execution_attempt_count
            updated_at
            deleted_at
        }
    }
`;

export const SUBSCRIPTION_ACTION_TYPES = gql`
    subscription liveActionType($projectId: Int64!) {
        liveActionType(projectId: $projectId) {
            id
            project_id
            name
            type
            delivering_timeout
            execution_timeout
            max_retries
            priority
            protocol_id
            created_at
            updated_at
            deleted_at
        }
    }
`;

export const CREATE_ACTION_TYPE = gql`
    mutation storeActionType($input: ActionTypeInput!) {
        storeActionType(input: $input) {
            id
            project_id
            name
            type
            delivering_timeout
            execution_timeout
            max_retries
            priority
            protocol_id
            deleted_at
        }
    }
`;

export const UPDATE_ACTION_TYPE = gql`
    mutation updateActionType($id: Int64!, $input: ActionTypeInput!) {
        updateActionType(id: $id, input: $input) {
            id
            project_id
            name
            type
            delivering_timeout
            execution_timeout
            max_retries
            priority
            protocol_id
            deleted_at
        }
    }
`;

export const DELETE_ACTION_TYPE = gql`
    mutation deleteActionType($id: Int64!, $projectId: Int64!) {
        deleteActionType(id: $id, projectId: $projectId) {
            id
            project_id
            name
            type
            delivering_timeout
            execution_timeout
            max_retries
            priority
            protocol_id
            deleted_at
        }
    }
`;
